import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './images/dpi_logo.png';

const Header = () => {

    const languageSelect = () => {

    }
    return (
        <header className='header'>
            <div className='header-top'>
                <div className='header-left'>
                <Link to="/">
                        <img src={logo} alt='Logo' className='logo' />
                    </Link>
                </div>
                <div className='header-center'>
                    <h1>Swa Dharma</h1>
                </div>
                <div className='header-right'>
                    <button>Lang</button>
                    <button>Login</button>
                </div>
            </div>
            <div className="navbar">
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
                <div className='dropdown'>
                    <a href='#services' className='dropbtn'>Services</a>
                    <div className='dropdown-content'>
                        <a href="https://samudyoga.swadharmaservices.in/" target="_blank" rel="noopener noreferrer">SamUdyoga</a>
                        <a href="http://samudwaaha.swadharmaservices.in/" target="_blank" rel="noopener noreferrer">SamUdwaaha</a>
                    </div>
                </div>
                <Link to="/products">Products</Link>
                <Link to="/information">Information</Link>
            </div>
        </header>
    );
};

export default Header;
