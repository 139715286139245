import React from 'react';
import InformationTabs from './InformationTabs';

const InformationSection = () => {
    return (
        <div className="information-section">
            <InformationTabs />
        </div>
    );
};

export default InformationSection;
