import React from 'react';
import './Homepage.css';
import logo from './images/dpi_logo.png';

const HomePage = () => {
    return (
        <div className="homepage">
            {/* Header */}
            {/* <header className="header-home">
                <img src={logo} alt="Logo" className="logo-home" />
                <h1>Swadharma Services</h1>
            </header> */}

            {/* Welcome Banner */}
            {/* <section className="welcome-banner">
                <h2>Supporting Family Traditions, Strengthening Communities</h2>
                <p>Preserving heritage through dedicated spiritual and cultural services for you and your family.</p>
            </section> */}

            {/* Key Sections */}
            <section className="mission-section">
                <h3>Our Mission</h3>
                <p>Swadharma Services is committed to empowering communities to uphold and celebrate their family traditions at home and within local temple events.</p>
            </section>
        </div>
    );
};

export default HomePage;
