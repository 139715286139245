import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const InformationTabs = () => {
    return (
        <div className="information-tabs">
            <h2>Information</h2>
            <Tabs>
                <TabList>
                    <Tab>Festivities</Tab>
                    <Tab>Rituals</Tab>
                    <Tab>Scholars</Tab>
                    <Tab>Audios</Tab>
                    <Tab>Videos</Tab>
                    <Tab>Products</Tab>
                </TabList>

                <TabPanel>
                    <h3>Festivities</h3>
                    <p>Information about community festivities, poojas, and celebrations.</p>
                </TabPanel>
                <TabPanel>
                    <h3>Rituals</h3>
                    <p>Details on family and temple rituals, rites, and ceremonies.</p>
                </TabPanel>
                <TabPanel>
                    <h3>Scholars</h3>
                    <p>Profiles and lectures of noted scholars in the community.</p>
                </TabPanel>
                <TabPanel>
                    <h3>Audios</h3>
                    <p>Audio files related to poojas, mantras, and teachings.</p>
                </TabPanel>
                <TabPanel>
                    <h3>Videos</h3>
                    <p>Recorded videos of events, ceremonies, and educational sessions.</p>
                </TabPanel>
                <TabPanel>
                    <h3>Products</h3>
                    <p>Information about traditional products and services.</p>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default InformationTabs;
